import React from 'react';
import Inner from '../components/templates/Inner';

import { Title } from '../components/atoms';

const NotFoundPage = () => (
  <Inner title="Page not found">
    <Title>Page not found</Title>
  </Inner>
);

export default NotFoundPage;
